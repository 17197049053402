@import 'board-2d';

cg-board {
  @extend %box-shadow, %abs-100;
  top: 0;
  left: 0;
  user-select: none;
  line-height: 0;
  background-size: cover;
  .manipulable & {
    cursor: pointer;
  }
}
square {
  position: absolute;
  pointer-events: none;
  &.move-dest {
    background: radial-gradient(rgba(20, 85, 30, 0.5) 19%, rgba(0, 0, 0, 0) 20%);
    pointer-events: auto;
  }
  &.premove-dest {
    background: radial-gradient(rgba(20, 30, 85, 0.5) 19%, rgba(0, 0, 0, 0) 20%);
    pointer-events: auto;
  }
  &.oc.move-dest {
    background: radial-gradient(transparent 0%, transparent 80%, rgba(20, 85, 0, 0.3) 80%);
  }
  &.oc.premove-dest {
    background: radial-gradient(transparent 0%, transparent 80%, rgba(20, 30, 85, 0.2) 80%);
  }
  &.last-move {
    will-change: transform;
    background-color: rgba(155, 199, 0, 0.41);
  }
  &.check {
    background: radial-gradient(ellipse at center, rgba(255, 0, 0, 1) 0%, rgba(231, 0, 0, 1) 25%, rgba(169, 0, 0, 0) 89%, rgba(158, 0, 0, 0) 100%);
  }
  &.selected {
    background-color: rgba(20, 85, 30, 0.5);
  }
  &.current-premove {
    background-color: rgba(20, 30, 85, 0.5);
  }
  &.move-dest:hover {
    background: rgba(20, 85, 30, 0.3);
  }
  &.premove-dest:hover {
    background: rgba(20, 30, 85, 0.2);
  }
  &.bh1 piece {
    opacity: 0.98;
  }
}
piece {
  position: absolute;
  top: 0;
  left: 0;
  background-size: cover;
  z-index: z('cg__piece');
  will-change: transform;
  pointer-events: none;
  &.dragging {
    cursor: move;
    z-index: z('cg__piece.dragging');
  }
  &.anim {
    z-index: z('cg__piece.anim');
  }
  &.fading {
    z-index: z('cg__piece.fading');
    opacity: 0.5;
  }
  &.ghost {
    opacity: 0.3;
  }
  &.black {
    &.king1, &.king2, &.king3 {
      text-align: center;
      color: white;
    }
  }
  &.white {
    &.king1, &.king2, &.king3 {
      text-align: center;
      color: black;
    }
  }
  &.king1:not(.ghost):before {
    content: '\25CF';
    margin-top: 90%;
    display: inline-block;
  }
  &.king2:not(.ghost):before {
    content: '\25CF \25CF';
    margin-top: 90%;
    display: inline-block;
  }
  &.king3:not(.ghost):before {
    content: '\25CF \25CF \25CF';
    margin-top: 90%;
    display: inline-block;
  }
}

cg-helper {
  position: absolute;
  display: table; // hack to round to full pixel size in chrome
  bottom: 0;
}

cg-container {
  position: absolute;
  display: block;
  bottom: 0;
}

.cg-wrap svg {
  overflow: hidden;
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  pointer-events: none;
  z-index: z('cg__svg');
  opacity: 0.6;
  image {
    opacity: 0.5;
  }
}
