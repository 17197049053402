@import 'default';

$theme: 'light';
$theme-light: true;
$theme-dark: false;

@mixin metal-bg {
  background: linear-gradient(to bottom, hsl(0, 0, 96) 0%, hsl(0, 0, 93) 100%);
}

@mixin metal {
  @include metal-bg;
  text-shadow: $text-shadow;
}
@mixin metal-hover {
  background: linear-gradient(to bottom, hsl(0, 0, 98) 0%, hsl(0, 0, 95) 100%);
  text-shadow: $text-shadow;
}

@mixin page-metal {
  background: linear-gradient(to bottom, mix($c-link, hsl(0, 0%, 100%), 20%) 0%, mix($c-link, hsl(0, 0%, 94%), 20%) 100%);
  text-shadow: $text-shadow;
}
@mixin page-metal-hover {
  background: linear-gradient(to bottom, mix($c-link, #fff, 25%) 0%, mix($c-link, c-light($c-bg-page, 90%), 25%) 100%);
  text-shadow: $text-shadow;
}

@mixin theme-style { }
