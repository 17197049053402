%flex-wrap {
  display: flex;
  flex-flow: row wrap;
}
%flex-center {
  /* extends %flex-wrap */
  display: flex;
  flex-flow: row wrap;
  align-items: center;
}
%flex-center-nowrap {
  display: flex;
  flex-flow: row;
  align-items: center;
}
%flex-between {
  /* extends %flex-center */
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: space-between;
}
%flex-between-nowrap {
  /* extends %flex-center */
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
}
%flex-between-stretch {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: stretch;
}
%flex-around {
  /* extends %flex-center */
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: space-around;
}
%flex-column {
  display: flex;
  flex-flow: column;
}
