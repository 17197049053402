html {
  box-sizing: border-box;
  min-height: 100%;
}

*, *::before, *::after {
  box-sizing: inherit;
  margin: 0;
  padding: 0;
}

body {
  background: $c-bg-page linear-gradient(to bottom, $c-body-gradient, $c-bg-page 116px) no-repeat;
  color: $c-font;
  overflow-x: hidden;
  &.fixed-scroll {
    /* prevents scroll bar flicker when dragging a piece out */
    overflow-y: scroll;
  }
}

a {
  color: $c-link;
  text-decoration: none;
  &:hover,
  &:active,
  &:focus {
    color: $c-link-hover;
  }
}

p {
  margin-bottom: 1em;
}

em, i {
  font-style: normal;
}

li {
  list-style: none;
}

time {
  font-size: 90%;
  opacity: 0.9; /* don't use c-color-dim, it overrides too hard */
}

hr {
  margin: 1.5rem 0;
  border: 0;
  height: 1px;
  background: $c-border;
}

small {
  font-size: .9em;
}

table, tbody, tfoot, thead, tr, th, td {
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: middle;
  text-align: inherit;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
