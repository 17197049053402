$board-image-path: "../images/board";

$board-files-100: (
'blue': 'svg/blue_100.svg',
'blue2': 'blue2_100.jpg',
'wood2': 'wood2_100.jpg',
'wood3': 'wood3_100.jpg',
'blue3': 'blue3_100.jpg',
'marble': 'marble_100.jpg',
'brown': 'svg/brown_100.svg',
'brown2': 'svg/brown2_100.svg',
'match': 'svg/match_100.svg',
'green': 'svg/green_100.svg',
'olive': 'olive_100.jpg',
'purple': 'svg/purple_100.svg',
'grey': 'grey_100.jpg',
'wood': 'wood-1024_100.jpg',
'canvas': 'canvas2-1024_100.jpg',
'leather': 'leather-1024_100.jpg',
'metal': 'metal-1024_100.jpg',
'maple': 'maple_100.jpg',
);

$board-files-64: (
'blue': 'svg/blue_64.svg',
'blue2': 'blue2_64.jpg',
'wood2': 'wood2_64.jpg',
'wood3': 'wood3_64.jpg',
'blue3': 'blue3_64.jpg',
'marble': 'marble_64.jpg',
'brown': 'svg/brown_64.svg',
'brown2': 'svg/brown2_64.svg',
'match': 'svg/match_64.svg',
'green': 'svg/green_64.svg',
'olive': 'olive_64.jpg',
'purple': 'svg/purple_64.svg',
'grey': 'grey_64.jpg',
'wood': 'wood-1024_64.jpg',
'canvas': 'canvas2-1024_64.jpg',
'leather': 'leather-1024_64.jpg',
'metal': 'metal-1024_64.jpg',
'maple': 'maple_64.jpg',
);

@each $color, $file in $board-files-100 {
  .#{$color} .is2d {
    .is100, &.is100 {
      cg-board {
        background-image: url(../images/board/#{$file});
      }
      square, piece {
        width: 10.0%;
        height: 10.0%;
      }
      fieldnumber {
        width: 10%;
      }
      cg-helper {
        width: 10.0%;
        padding-bottom: 10.0%;
      }
      cg-container {
        width: 1000%;
        height: 1000%;
      }
    }
  }
}
@each $color, $file in $board-files-64 {
  .#{$color} .is2d {
    .is64, &.is64 {
      cg-board {
        background-image: url(../images/board/#{$file});
      }
      square, piece {
        width: 12.5%;
        height: 12.5%;
      }
      fieldnumber {
        width: 12.5%;
      }
      cg-helper {
        width: 12.5%;
        padding-bottom: 12.5%;
      }
      cg-container {
        width: 800%;
        height: 800%;
      }
    }
  }
}