.data-count {
  position: relative;
}
.data-count::after,
.unread {
  @extend %active, %box-radius;
  padding: 1px 5px 1px 4px;
  font-weight: bold;
  font-size: 13px;
}
.data-count::after {
  content: attr(data-count);
  top: -5px;
  right: 0;
  position: absolute;
  padding: 0 3px;
  height: 15px;
  line-height: 15px;
}
.data-count[data-count='0']::after {
  display: none;
}
