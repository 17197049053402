.main-board {
  @extend %square;
  &__preload {
    position: absolute;
  }
  .cg-wrap {
    @extend %abs-100;
  }
}

.cg-wrap {
  display: table; // part of hack to round to full pixel size in chrome
}

.mini-board {
  @extend %square;
  display: table;
}
