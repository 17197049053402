.is::before,
[data-icon]::before,
.is-after::after {
  @extend %data-icon;
}
.is.text::before,
.text[data-icon]::before {
  margin-right: .4em;
}

.user-link.online .line,
.is-green::before {
  color: $c-good;
}
.is-red::before {
  color: $c-bad;
}
.is-gold::before {
  color: $c-brag;
}
